// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Text, Intro, BlocksList } from "components";

import img1 from "../images/illustrazioni/mobile_development.png";
import img2 from "../images/illustrazioni/personalizza.png";
import img3 from "../images/illustrazioni/data_cloud.png";
import img4 from "../images/illustrazioni/tecnologia.png";
import img5 from "../images/illustrazioni/startup.png";
import img6 from "../images/illustrazioni/support.png";
import img7 from "../images/illustrazioni/presentation.png";
import img8 from "../images/illustrazioni/mail_automation.png";

const pageProperties = {
  title: "Devi realizzare una app o una nuova idea su web?",
  description:
    "Sviluppo agile: piccoli passi per mete lontane",
};

const blocks = [
  {
    title: "Web Development",
    description: `A partire da semplici portali di vendita e CMS, fino a complesse web application come biglietterie, sistemi di tracciamento mutipiattaforma, sviluppo o intergrazione di API.
    Realizziamo da zero applicazioni web full stack con le migliori tecnologie del momento o facciamo crescere il tuo software già esistente.`,
    image: img1,
  },
  {
    title: "App e Mobile development",
    description: `Oggi accediamo a internet per lo più dal cellulare. Anche i tuoi clienti.
    Ti serve una app nativa per ios e android o una applicazione web responsive?
    Ti guidiamo nella scelta della soluzione giusta e realizziamo applicazioni per cellulare e web app mobile oriented.`,
    image: img2,
  },
  {
    title: "Software engineering & System integration",
    description: `Non tutti possono gestire un team tecnico o scegliere e gestire gli strumenti per aiutarti a raggiungere i risultati prefissi. In certi contesti diventa complesso anche solo far dialogare sistemi informatici diversi che assolvono a varie funzioni aziendali. Questo è il motivo per cui alcune aziende cercano team esterni che possano dare loro l’aiuto determinante: finire le parti mancanti e lanciare i loro prodotti. Con la nostra esperienza nella gestione di soluzioni mission critical, siamo sicuri che otterrete i risultati desiderati.`,
    image: img3,
  },
  {
    title: "Data Handling",
    description: `Oggi non si fa che parlare di big data. In un mondo sempre più guidato dai numeri e dai dati, per le aziende diventa sempre più cruciale la raccolta di informazioni e la loro analisi.
    Sviluppando per il web tutte le nostre soluzioni sono data-driven, ovvero impostate su numeri e statistiche, orientate alla raccolta di dati e alle statistiche.
    In questa ottica sviluppiamo e integriamo soluzioni per permettere alla tua azienda di raccogliere informazioni ed effettuare il trattamento dei dati essenziali. Progettiamo l’architettura delle informazioni, la loro raccolta e infine l’analisi.
    Il processo di data mining è fondamentale e non basta avere un discreto database di informazioni per poterne tirare fuori valore.
    Assistiamo le aziende nello sviluppo di questo processo che è necessario per integrare automatismi complessi e capacità decisionali
    `,
    image: img4,
  },
  {
    title: "Affiancamento Startup",
    description: `Vuoi trasformare la tua intuizione in realtà? Il progetto può fare la differenza.
    Un’idea, anche la più brillante, può franare sotto il peso di uno sviluppo infinito e costi di gestione crescenti.
    Applicando il metodo LEAN alla tua startup, ti assisteremo nella realizzazione del tuo MVP, cioè il prodotto minimo che ti permetterà una crescita sostenibile e il contenimento dei costi, col quale potrai iniziare a promuoverti e farti conoscere.`,
    image: img5,
  },
  {
    title: "Infrastrutture Cloud & Server",
    description: `App Web, API, App per dispositivi mobili, siti web, hanno tutte bisogno di un posto dove essere eseguite e questo è il tuo cloud o il tuo server. Il cloud non è solo un luogo magico in cui tutto sarà in esecuzione, è un ambiente che richiede lavoro e attenzione per adattarsi alle tue esigenze. Forniamo consulenza per progettare e realizzare la tua architettura o gestiamo i tuoi server.`,
    image: img6,
  },
  {
    title: "Security & Data Protection",
    description: `Tutte le aziende, indipendentemente dalle loro dimensioni e dal settore in cui operano, sono potenzialmente soggette ad attacchi mirati ai loro sistemi informativi.
    Forniamo consulenze di vulnerability assessment e all’occorrenza penetration test, nonché analisi di intrusioni e minacce informatiche.
    Un penetration test simula un vero attacco informatico allo scopo di testare lo stato della sicurezza informatica di una rete, un sito, un ufficio o un’azienda.`,
    image: img7,
  },
  {
    title: "Digitalizzazione d'impresa",
    description: `Un qualsiasi ufficio può trarre vantaggi indiscutibili di produttività e qualità del lavoro solo adottando i giusti strumenti.
    Ti possiamo aiutare ad individuare e configurare servizi e software utili a velocizzare la tua quotidianità aziendale. Soluzioni in cloud e distribuite di terze parti che oltretutto abbasseranno costi e investimenti in tecnologia, garantendo maggior sicurezza e aggiornamenti continui.
    Rivoluziona la tua attività affidandoti ai consulenti giusti.`,
    image: img8,
  },    
];

function RealizzazioneAppMobilePage() {
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Realizzazione app per cellulari e applicazioni web a Prato, Firenze e Pistoia"
        description="Siamo specializzati nel creare app per smartphone e progetti web innovativi e scalabili. Ci occupiamo anche di sicurezza, progettazione, digitalizzazione d’impresa."
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-28">
          <Intro {...pageProperties} />
        </section>

        <section className="intro-page">
            <div className="flex flex-col items-left px-8 md:px-28 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
            <Title headingLevel="h2">Non siamo innamorati degli strumenti, ma delle soluzioni</Title>

❌Hai appaltato lo sviluppo di un software ad una web agency, un freelance, uno o più sviluppatori e poi per diversi motivi lo sviluppo del software è rimasto fermo e tu sei bloccato?
<br/>
❌Hai un software aziendale obsoleto? Che non è più adeguato alle tue esigenze di business? Che magari non è a norma? Su cui è impossibile mettere le mani?
<br/>
❌Hai un progetto o un’idea di realizzare un software aziendale ma non hai mai iniziato seriamente lo sviluppo con alcun fornitore e non sai da dove iniziare?
<br/><br/>

<strong>Smetti di inseguire i tuoi fornitori. Con noi avrai:</strong>
<br/>
✔️Un team di programmatori e sistemisti esperti sarà in grado di rispondere a qualsiasi vostra esigenza di progettazione, sviluppo e deployment.
<br/>
✔️La nostra esperienza e le nostre competenze ci permettono di poter seguire qualsiasi progetto web e inserirci e collaborare con qualsiasi team.
            </div>
        </section>
        
        <section className="">
          <BlocksList blocks={blocks} type="images" />
        </section>

        <section className="closurePage">
            <div className="flex flex-col items-center px-8 md:px-28 py-16">
                <Title headingLevel="h2">Il metodo: un passo alla volta verso grandi mete.</Title>  
                <p>
                Siamo convinti sostenitori del metodo LEAN e dello sviluppo AGILE.
                La Lean Startup è un insieme di metodologie utilizzate per sviluppare business sostenibili in contesti di incertezza.
                Si tratta di un approccio radicale per il lancio di tutte le iniziative innovative che riduce drasticamente tempi e costi e, di conseguenza, le probabilità di fallire, attraverso l’utilizzo di metodologie chiamate “agili”.
                <br/>
                L’idea è quella di partire con un cosiddetto MVP, “minimum viable product”, cioè “prodotto minimo commercializzabile”, per poi adattare strada facendo il prodotto alle necessità del cliente proprio grazie ai feedback ricevuti da quest’ultimo, dando così, da un lato, una risposta sempre presente e coerente alla clientela, e dall’altro, poiché vengono ridotti tempi e costi, si minimizzano i rischi di mercato.
                Da un punto di vista tecnico questo si traduce nel Metodo Agile. Non si basa sull’approccio classico e lineare di progettazione, ma sulla possibilità di realizzare un progetto per fasi, chiamate “sprint”. Ad ogni sprint corrisponde una nuova funzionalità e viene verificata la soddisfazione del cliente, al quale viene mostrato il lavoro svolto fino a quel punto.
                <br/>
                Un sistema iterativo (ed interattivo) che consente di apportare agilmente modifiche al progetto, di abbattere i costi di produzione e, soprattutto, di evitare effort inutili ed un eventuale fallimento del progetto.
                <br/>
                I nostri software seguono quindi un processo di sviluppo supportato da strumenti oggi giorno fondamentali sia per la scrittura del codice, che per la condivisione col cliente.
                </p>
            </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default RealizzazioneAppMobilePage;
